import React ,{ useState,useEffect }  from 'react'
import api from "../../../api";
export default function ChatStatus(props) {
    const [chatlog, setchatlog] = useState({started: 0, completed: 0, livechat: 0});
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        getChatStats(props);
    }, [props.chatfrequency, props.startdaterange, props.enddaterange]);

    
    const getChatStats = (props) => {
        setLoading(true);
        const params = {
			frequency: props.chatfrequency,
			filterstartdate: props.startdaterange,
			filterenddate: props.enddaterange
		};
		const esc = encodeURIComponent;
		const query = Object.keys(params)
			.map((k) => esc(k) + "=" + esc(params[k]))
			.join("&");
       api.chatsetup.getchatstatus(query).then((res) => {
        try {
            if (res.status) {
                setLoading(false);
                setchatlog(res.chatStats);
            }
        } catch (error) {
            console.log(error);
            setLoading(false);
            
        }
           
        });
    }
    
  return (
    <div className="col-md-12">
    <h2 className="mt-31">Chats</h2>
    {loading ? <div style={{ width: '100%', height: 200 }}>
					<div class="ui active centered inline loader"></div>
				</div> : <ul className="zy-chatStats">
          
                <>
                    <li>
                        <label>Started</label>
                        <h4>{chatlog?.started}</h4>
                    </li>
                    <li>
                        <label>Completed</label>
                        <h4>{chatlog?.completed}</h4>
                    </li>
                    <li>
                        <label>Live Chat</label>
                        <h4>{chatlog?.livechat}</h4>
                    </li>
                </>
           
        </ul>}
    </div>
  )
}
