import React, { useEffect,useState  } from "react";
import {  Message, Segment,Table,Divider, Button } from "semantic-ui-react";
import api from "../../api";
import ConfirmDialog from "../Modal/ConfirmDialog";


class Zaptoken extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tokens:[],
      successMessage: null,
      errors: null,
      inputkey:1,
      loading:false,
      message:"",
      hidden:true,
      modalIsOpen:false,
     
    };
    
  }
  componentDidMount() {
    this.loadData();
  }

  loadData=()=>{
    api.dialogflow.getZaptoken().then((res)=>{
      console.log(res,"tokendata");
      if(res.status && res.token){
        this.setState({token:res.token,tokens:res.token});
      }

    }).catch(err=>{
      console.log(err);
    });
      
  }
  closeModal = ()=>{
    this.setState({modalIsOpen:false,webhookStatus:true})
  }
  openModal = (e)=>{
    if(this.state.token.length){
      this.setState({modalIsOpen:false,tokens:[{key:"fgegfegfhee",hidden:false,primary:true}]})
    }else{
      this.finalSave(e);
    }
    
  }
  handleSubmit=(e)=>{
    this.setState({modalIsOpen:false});
     this.finalSave(e);
  }

finalSave = (e) =>{
  this.setState({loading:true});
  let formData={
      stchatstatus:this.state.token,
  }
  api.dialogflow.saveZapToken(formData).then(resp=>{
      if(resp.status){
        let zap_apis=resp.token;
         this.setState({successMessage:"Token generated successfully.",loading:false,tokens:zap_apis});  
      }else{
        this.setState({loading:false,successMessage:null,errors:"There is a error."});
      }
      setTimeout(() => {
        this.setState({loading:false,successMessage:null,errors:null});
      }, 4000);
  });
  
}

copyLink = (id,i) => {
  id=id+"_"+i;
  const linktocopy = document.getElementById(id).innerText;
  navigator.clipboard.writeText(linktocopy);
  let linkcopysuccess = document.getElementById('tokencopysuccess_'+i);
  linkcopysuccess.style.display = 'inline';
  setTimeout(() => {
      linkcopysuccess.style.display = 'none';
  }, 2000);
}
showhide=(i,hide)=>{
  let lists=this.state.tokens;
  lists[i].hidden=!lists[i].hidden;
  this.setState({tokens:lists});
}
deletetoken=(i)=>{
  let lists=this.state.tokens;
  this.setState({modalIsOpen:true});
  
}
confirmdelete=()=>{
  let lists=this.state.tokens;
  this.setState({loading:true,modalIsOpen:false});
  api.dialogflow.deleteZapToken({}).then(resp=>{
    if(resp.status){
      lists.splice(1,1);
       this.setState({successMessage:"Token deleted successfully.",loading:false,tokens:lists});  
    }else{
      this.setState({loading:false,successMessage:null,errors:"There is a error."});
    }
    setTimeout(() => {
      this.setState({loading:false,successMessage:null,errors:null});
    }, 4000);
});
  
}

markprime=(i)=>{
  let lists=this.state.tokens;
  api.dialogflow.primeZapToken({}).then(resp=>{
    if(resp.status){
      lists=lists.map(item=>{
        item.primary=!item.primary;
        return item;
      });
      lists=lists.reverse();
       this.setState({successMessage:"Marked as primary.",loading:false,tokens:lists});  
    }else{
      this.setState({loading:false,successMessage:null,errors:"There is a error."});
    }
    setTimeout(() => {
      this.setState({loading:false,successMessage:null,errors:null});
    }, 4000);
});
  
  this.setState({tokens:lists});
}

  
  render() {
    const errorLabel = <div className="red" pointing={"true"} />;

    return (
      <React.Fragment>
        <div className="row">
        {this.state.modalIsOpen && (this.state.token.length>0) && <ConfirmDialog
                show={this.state.modalIsOpen }
                toggleModal={this.confirmdelete}
                closeAction={this.closeModal}
                title="Confirm your action"
                message={"Are you certain you want to delete this token? Doing so will halt the current Zaps using this token, and you'll need to either create new ones or update the existing ones."}
              />}

    
        {this.state.loading && <div className="ui loading form"></div>}
          <div className="col-lg-12 col-md-12 col-sm-12">
            {this.state.successMessage && (
              <Message color={"green"}>{this.state.successMessage}</Message>
            )}
            <div className="kt-portlet__body kt-portlet__body--fit ">
            {this.state.tokens.length<2 &&<button
                      type="submit"
                      style={{ float: "right",marginLeft:'70%' }}
                      className="btn btn-block  linear-gradient yr-submitbtn btn-sm pull-right"
                      onClick={this.finalSave}
                    >
                      Generate Token
                    </button>}
                    <Divider />
            <div className="yr-manageUser-table">
              <Segment>
              <div className="table__wrapper">
              {this.state.tokens.length>0 ?
              <Table celled selectable sortable>
                  <React.Fragment>
                  <Table.Header>
                    <Table.Row key={23}>
                <Table.HeaderCell style={{minWidth:'50%',maxWidth:'80%',width:'800px!important'}}>
                 API KEY
              </Table.HeaderCell>
              <Table.HeaderCell>
                 Actions
              </Table.HeaderCell>
              
              </Table.Row>
                  </Table.Header>
                </React.Fragment>
                <Table.Body>
                {this.state.tokens.map((tokendata,i)=>{
               return(<Table.Row key={i} >
                <Table.Cell style={{minWidth:'50%',maxWidth:'80%',width:'800px!important'}}>
                <strong style={{fontWeight:'600'}}>Api Token :</strong>&nbsp;
                <span id={`tokeval_${i}`}>{tokendata.hidden ? '***************************' :tokendata.key}</span>
                &nbsp; <strong style={{fontWeight:600}}>{tokendata.primary ? '(Primary)':''}</strong> &nbsp;{!tokendata.hidden && <span><i className="fa fa-copy" style={{ marginLeft: 20, cursor: 'pointer' }} onClick={() => this.copyLink('tokeval',i)}></i>
                <i id={`tokencopysuccess_${i}`} className="fa fa-check" style={{ color: 'green', fontSize: '1.3em !important', marginLeft: 5, display: 'none',cursor:'pointer' }} title="Copy token"></i></span>}
                {!tokendata.primary && <button style={{width:'95px',fontSize:'12px',padding:'1px',display:'block',marginTop:'10px'}} className="btn linear-gradient yr-submitbtn" onClick={() => this.markprime(i)}>Mark As Primary</button>}
                </Table.Cell>
                
                <Table.Cell style={{width:'30%'}}>
                <i className={tokendata.hidden ? 'fa fa-eye-slash':'fa fa-eye'} style={{ marginLeft: 20, cursor: 'pointer' }} onClick={() => this.showhide(i,tokendata.hidden)}></i>
                {!tokendata.primary && <i className='fa fa-trash' style={{ marginLeft: 20, cursor: 'pointer' }} onClick={() => this.deletetoken(i)}></i>}
                {/* {!tokendata.primary && <i className='fa fa-check' title="mark primary" style={{ marginLeft: 20, cursor: 'pointer' }} onClick={() => this.markprime(i)}></i>} */}
                </Table.Cell>
                
               </Table.Row> )
              

            })}
              </Table.Body>
                </Table>:<div> No API Tokens</div>
                }
                </div>
             
              </Segment>
            </div> 
              
            
            </div>
          </div>
        </div>
       
      </React.Fragment>
    );
  }
}



export default Zaptoken;
