import React, { useState, useEffect } from 'react';
import api from "../../api";
import { Message,Form  } from "semantic-ui-react";
import ConfirmDialog from "../Modal/ConfirmDialog";
import Switch from "react-switch";
import "./integration.css"


const ServiceTitan = (props) => {
    const [defaultState, setdefaultState] = useState({
        loading: false,
        modal: false,
        successMessage: null,
        errorMessage: null,
        dataLoading: null,
        listdata: true,


    });
    const [isEnabled,setIsEnabled] = useState(false);
    const [stChatEnabled, setStChatEnabled] = useState('OFF');
    const [stAiChatEnabled, setStAiChatEnabled] = useState('OFF');
    const [stAiTurboEnabled, setStAiTurboEnabled] = useState('OFF');
    const [stCustomerEnabled, setStCustomerEnabled] = useState(false)
    const [webChecked, setWebChecked] = useState('OFF')
    const [aiChecked, setAiChecked] = useState('OFF');
    const [turboChecked, setTurboChecked] = useState('OFF');
    const [createUserChecked, setCreateUserChecked] = useState('OFF')
    const [errors, setError] = useState('');
    const [videoURL,setVideoURL] = useState('');
    const [stToggleStatus, setStToggleStatus] = useState(false)
    const [chatBranchNameList, setChatBranchNameList] = useState([])
    const [chatServiceProviderList, setChatServiceProviderList] = useState([])
    const [addedChatBranchName, setAddedChatBranchName] = useState([]);
    const [addedTurboBranchName, setAddedTurboBranchName] = useState([])
    const [selectChatBranchName, setSelectBranchName]=useState(null)
    const [selectChatProviderValue, setSelectChatProviderValue]=useState(null)
    const [selectChatProviderName, setSelectChatProviderName]=useState(null)

    const [selectChatCallProviderValue, setSelectChatCallProviderValue]=useState(null)
    const [selectChatCallProviderName, setSelectChatCallProviderName]=useState(null)

    const [selectTurboProviderValue, setSelectTurboProviderValue]=useState(null)
    const [selectTurboProviderName, setSelectTurboProviderName]=useState(null)

    const [workSpaceId, setWorkSpaceId]=useState(null)
    const [loading, setLoading] = useState(false)
    const [deleteBranchAlert, setDeleteBranchAlert] = useState(false)
    const [deleteBranchId, setDeleteBranchId] = useState(null)
    const [deleteBranchType, setDeleteBranchType]=useState('')
    const [nullWebBranch, setNullWebBranch]=useState(false)
    const [showStToggles, setShowStToggles]=useState(false)
    const [continueBtn, setContinueBtn]=useState(true)
    const [disabledWebAddBtn, setDisabledWebAddBtn]=useState(false)
    const [disabledWebAiAddBtn, setDisabledWebAiAddBtn]=useState(false)
    const [disabledTurboAddBtn, setDisabledTurboAddBtn]=useState(false);
    // ai call branch name list 
    const [aiCallBranchNameList, setAiCallBranchNameList] = useState([]);
    const [turboBranchNameList, setTurboBranchNameList] = useState([]);
    const [turboBranchValues, setTurboBranchValues] = useState([]);
    const [turboBranchOptions, setTurboBranchOptions] = useState([]);
    const [callServiceProviderList, setCallServiceProviderList] = useState([]);
    const [turboServiceProviderList, setTurboServiceProviderList] = useState([]);
    const [addedAiChatBranchName, setAddedAiChatBranchName] = useState([]);
    const [selectAiChatBranchName, setSelectAiBranchName]=useState(null);
    const [selectTurboBranchName, setSelectTurboBranchName]=useState(null);
    
    const [aiWorkSpaceId, setAiWorkSpaceId]=useState(null)
    const [nullAiWebBranch, setNullAiWebBranch]=useState(false);
    const [nullTurboBranch, setNullTurboBranch]=useState(false)
    // const [chatDropDown, setChatDropDown] = useState(false)
    // const [aiCallDropDown, setChatDropDown] = useState(false)
    // const [totalCount,props.] = useState(0)
    const [onForChat,setOnForChat] = useState(false);

    // const [serviceTitanInfo, setserviceTitanInfo] = useState({});
    // const [confirmDialog, setConfirmDialog] = useState(false);
    const [model, setModel] = useState(false);
    const [model2, setModel2] = useState(false);
    const [disableModalIsOpen,setDisableModalIsOpen] = useState(false)
    const [enableModalIsOpen,setEnableModalIsOpen] = useState(false)
    const [clientIdTitan, setClientIdTitan]=useState("");
    const [clientSecretTitan, setClientSecretTitan]= useState("");
    const [appKeyTitan, setAppKeyTitan]=useState("");
    const [tenantIdTitan, setTenantIdTitan]= useState("");
    const [updateCredBtn, setUpdateCredBtn]=useState(false)
    const [discardBtn, setDiscardBtn]=useState(false)
    const [enableSt,SetEnableSt]=useState(false)
    const [disableAlert, setDisableAlert]=useState(false)
    const [inputkey, setinputkey]=useState(1);
    

    const getListData =()=>{
        setLoading(true)
        getDataSource().then((res) => {
            setDisableAlert(false)
            setLoading(true)
            if(res.totalCount == 0 ){
                setClientIdTitan("");
                setClientSecretTitan("")
                setAppKeyTitan("")
                setTenantIdTitan("")
                setIsEnabled(false)
            }
            if(res.list[0].st_client_id.length){
                setShowStToggles(true)
                setContinueBtn(false)
            }
            if (res.totalCount > 0) {
                setdefaultState({
                    ...defaultState,
                    listdata: false, 
                    loading:false
                })
                setLoading(false)
                SetEnableSt(true)
                setDiscardBtn(false)
                if(res.chat_on=="1"){
                    setIsEnabled(true)
                }
                if(res.chat_on == null || res.chat_on == "0"){
                    setIsEnabled(false)
                }else{
                    setUpdateCredBtn(true)
                }
                props.setData({
                            clientId:res.list[0].st_client_id,
                            clientSecret:res.list[0].st_client_secret,
                            appKey:res.list[0].st_app_key,
                            tenetId:res.list[0].st_tenet_id,
                            webOnOff:res.list[0].st_web_on,
                            voiceOnOff:res.list[0].st_voice_on,
                            createUserOnOff:res.list[0].st_create_user_on,
                            turboON:res.list[0].turbo_on,
                            id:res.list[0].st_id 
                        }) 
                        setClientIdTitan(res.list[0].st_client_id);
                setClientSecretTitan(res.list[0].st_client_secret)
                setAppKeyTitan(res.list[0].st_app_key)
                setTenantIdTitan(res.list[0].st_tenet_id)
                props.setTotalCount(res.totalCount)
                validateForm();
                if(res.chat_on !== "0"){
                    if(res.chat_on !== null){
                        // props.setData({
                        //     clientId:res.list[0].st_client_id,
                        //     clientSecret:res.list[0].st_client_secret,
                        //     appKey:res.list[0].st_app_key,
                        //     tenetId:res.list[0].st_tenet_id,
                        //     webOnOff:res.list[0].st_web_on,
                        //     voiceOnOff:res.list[0].st_voice_on,
                        //     createUserOnOff:res.list[0].st_create_user_on,
                        //     id:res.list[0].st_id 
                        // })   
                    }
                }           
                setWebChecked(res.list[0].st_web_on);
                setStChatEnabled(res.list[0].st_web_on);
                setAiChecked(res.list[0].st_voice_on);
                setTurboChecked(res.list[0].turbo_on);
                setStAiTurboEnabled(res.list[0].turbo_on);
                setStAiChatEnabled(res.list[0].st_voice_on);
                setCreateUserChecked(res.list[0].st_create_user_on);
                setinputkey(Math.random() * 100);
                setLoading(false)
            }else{
                props.setData({
                    clientId:"",
                    clientSecret:"",
                    appKey:"",
                    tenetId:"",
                    webOnOff:"OFF",
                    voiceOnOff:"OFF",
                    turboON:"OFF",
                    createUserOnOff:"OFF",
                    id:"0"
                }) 
                setUpdateCredBtn(false)
            }
            if(res.chat_on==="0" || res.chat_on===null){
                setOnForChat(false)
                props.setChatOnValue("0")
            }else{
                setOnForChat(true)
                props.setChatOnValue(res.chat_on)
                setIsEnabled(true)
            }
            if(res.engage_on===null){
                props.setEngageOnValue("0")
            }else{
                props.setEngageOnValue(res.engage_on) 
            }
            setLoading(false);
        }).catch((err) => { console.log(err); setLoading(false) });
    }

    useEffect(() => {
        getListData() 
        getChatBranchName();
        getAiCallBranchName();
        servieTitanchatTurboBranchList();
        getTurboData();
        getChatServiceProviderName();
    }, []);

    // useEffect(()=>{
    //     getListData();
    // },[props.activeMenu])


    // useImperativeHandle(ref, () => ({
    //     getListData
    //   }));

    // data list 
    const getDataSource = (params) => api.servicetitan.list(params);
  
    const handleChange = (e) => {
        setContinueBtn(true)
        setDisableAlert(true)
        if(e.target.name == 'clientId'){
            setClientIdTitan(e.target.value);
            props.setData({...props.dataEngage,[e.target.name]:e.target.value})
        }
        if(e.target.name == 'clientSecret'){
            setClientSecretTitan(e.target.value);
            props.setData({...props.dataEngage,[e.target.name]:e.target.value})
        }
        if(e.target.name == 'appKey'){
            setAppKeyTitan(e.target.value);
            props.setData({...props.dataEngage,[e.target.name]:e.target.value})
        }
        if(e.target.name == 'tenetId'){
            setTenantIdTitan(e.target.value);
            props.setData({...props.dataEngage,[e.target.name]:e.target.value})
        }
        if (errors && errors[e.target.name]) {
            props.setData({...props.dataEngage,[e.target.name]:e.target.value})
            setError({...errors,[e.target.name]:""})
        } else {
          props.setData({...props.dataEngage,[e.target.name]:e.target.value})
        }
      };
    const validateForm = ()=> {
        let errors = {}; 
        
        if(clientIdTitan == ""){
          errors.clientId = "Please enter client id."
        }
        if(clientSecretTitan == ""){
          errors.clientSecret = "Please enter client secret."
        }
        if(appKeyTitan == ""){
          errors.appKey = "Please enter app key."
        }
        if(tenantIdTitan == ""){
          errors.tenetId = "Please enter tenant id."
        }
        return errors;
      }

    const modalBeforeEnable = (type)=>{
        setDisableAlert(true)
        const error = validateForm();
        if(Object.keys(error)?.length>0){
            setError(error);
            // if(errors.hasOwnProperty('clientId')){
            //   setError({ errors:{...errors,clientId:errors.clientId} });
            // }
        }else{
          if(stToggleStatus==false){
                setStToggleStatus(true)
                setEnableModalIsOpen(true)
          }else{
              setEnableModalIsOpen(true)
              setError("");
            }
        }
      }
    
    /* Disable service titan for chat */
    const deleteCredentials = () => {    
        setdefaultState({
            ...defaultState,
            loading: true,
            
        });
        
            const formData = {
                user_client_id:props.data.clientId,
                user_client_secret:props.data.clientSecret,
                user_app_key:props.data.appKey,
                user_tenet_id:props.data.tenetId,
                chat_on:"0",
                engage_on:props.engageOnValue,
                id:props.data.id
            }
            api.servicetitan.deleteCredential(formData)
                .then((resp) => {
                    setDisableAlert(false)
                    console.log(resp);
                    setShowStToggles(false)
                    if (resp && resp.message) {
                        setUpdateCredBtn(false)
                        setDiscardBtn(false)
                        setLoading(false)
                        setdefaultState({
                            ...defaultState,
                            successMessage: resp.message,
                            loading: false,
                            
                        });
                        props.setData({
                            clientId:"",
                            clientSecret:"",
                            appKey:"",
                            tenetId:"",
                            webOnOff:"OFF",
                            voiceOnOff:"OFF",
                            chat_on:"0",
                            turboON:"OFF",
                            createUserOnOff:"OFF",
                            id:0  
                        })
                        props.setDataEngage({
                            clientId:"",
                            clientSecret:"",
                            appKey:"",
                            tenetId:"",
                            engage_on:"0",
                            id: 0,  
                        })
                        props.setChatOnValue('0')
                        setOnForChat(false)
                        setIsEnabled(false)
                        // utilFunc.scrollTop(800);
                        setTimeout(() => {
                            setdefaultState({
                                ...defaultState,
                                successMessage: null,
                                loading: false,
                                listdata: true
                            });
                            getListData()
                        }, 4000)
                    }else if (resp.errorMessage) {
                        setdefaultState({
                            ...defaultState,
                            errorMessage: resp.errorMessage,
                            loading: false,
                            // id: 0,
                        });
                    }
                })
                .catch((err) => {
                    setdefaultState({
                        ...defaultState,
                        loading: false,
                        // id: 0,
                    });
                })
    }

   
    const handleSwitch = (checked) => {
        console.log(props.user)
        if(isEnabled ){
            if(props.chatOnValue=="1"){
                disableModalOpen()
            }else{
                setIsEnabled(false)
            }
        }else{
            if(enableSt==true){
                updateStStatus("1")
                // setIsEnabled(true)
        }else{
            setIsEnabled(true)
        }
    }
    }


    const handleToggles = (type) => {
        if(type==1){
            console.log(props)
            console.log(props.data.webOnOff)
            if(stChatEnabled=='ON' || webChecked=='ON'){
                setWebChecked('OFF')
                setStChatEnabled('OFF')
            }else{
                setWebChecked('ON')
                setStChatEnabled('ON')
            }
        }
        if(type==2){
            if(stAiChatEnabled=='ON' || aiChecked=='ON'){
                setAiChecked('OFF')
                setStAiChatEnabled('OFF')
            }else{
                setAiChecked('ON')
                setStAiChatEnabled('ON')
            }
        }
        if(type==3){
            if(stCustomerEnabled==true || createUserChecked=='ON'){
                setCreateUserChecked('OFF')
                setStCustomerEnabled(false)
            }else{
                setCreateUserChecked('ON')
                setStCustomerEnabled(true)
            }
        }
        if(type==4){
            if(stAiTurboEnabled=='ON' || turboChecked=='ON'){
                setTurboChecked('OFF')
                setStAiTurboEnabled('OFF')
            }else{
                setTurboChecked('ON');
                setStAiTurboEnabled('ON');
            }
        }
    }

    const enableModalOpen = ()=>{
        setEnableModalIsOpen(true)
    }

    const disableModalOpen = ()=>{
        setDisableModalIsOpen(true)
    }

    const enableModalClose = ()=>{
        setEnableModalIsOpen(false)
    }

    const deleteModalClose = ()=>{
        setDeleteBranchAlert(false)
    }

    const disableModalClose = ()=>{
        setDisableModalIsOpen(false)
    }

    const disableServiceTitan = ()=>{
        setDisableModalIsOpen(false)
        deleteCredentials()
    }

    // final save branch and service Providers Toggles

    const finalServiceTitanSave = () =>{
        setLoading(true)
        let formData={
            user_id:props.user.user_id,
            user_web_on:webChecked,
            user_voice_on:aiChecked,
            user_on:createUserChecked,
            turbo_on:turboChecked,
        }
        api.servicetitan.saveFinalStatus(formData).then(resp=>{
            if(resp.status){
                getListData()
                setLoading(false)
            }
        })
    }

    /* Enable Service Titan for chat */
    const  enableServiceTitan = (id) => {
        if(Object.keys(errors)?.length===0){
            setdefaultState({...defaultState,loading:true});
            setEnableModalIsOpen(false);
            let formData={}
            if(props.totalCount>0){
                formData = {
                    user_client_id:clientIdTitan,
                    user_client_secret:clientSecretTitan,
                    user_app_key:appKeyTitan,
                    user_tenet_id:tenantIdTitan,
                    user_chat_on:id,
                    user_engage_on:props.engageOnValue,
                    type:"chat",
                }
                if(props.data.id==undefined){
                    formData["id"]=props.dataEngage.id;
                }else{
                    formData["id"]=props.data.id;
                }
            }else{
                formData = {
                    user_client_id:clientIdTitan,
                    user_client_secret:clientSecretTitan,
                    user_app_key:appKeyTitan,
                    user_tenet_id:tenantIdTitan,
                    user_chat_on:"1",
                    user_engage_on:props.engageOnValue,
                    type:"chat",
                }
            }
            console.log(formData)
            api.servicetitan.createNew({data:formData}).then(resp=>{
                if(resp && resp.message){
                    setdefaultState({...defaultState,loading:false,successMessage:resp.message});
                    setShowStToggles(true)
                    setContinueBtn(false)
                    document.getElementById('formChat').reset();
                    setTimeout(() => {
                        setdefaultState({
                            ...defaultState,
                            loading: false,
                            successMessage: null,
                            listdata : false,
                        });
                    }, 4000)  
                    getListData()
                    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
                }else {
                    setClientIdTitan("");
                        setClientSecretTitan("");
                        setAppKeyTitan("");
                        setTenantIdTitan("");
                        setDiscardBtn(true)
                        setUpdateCredBtn(true)
                    setdefaultState({
                        ...defaultState,
                        errorMessage: resp.errorMessage,
                        // id: 0,
                        loading: false
                    });
                    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
                    // setModel(false);
                    // setFormLoading(false);
                    // setserviceTitanInfo({});
                    document.getElementById('formChat').reset();
                    setTimeout(() => {
                        setdefaultState({
                            ...defaultState,
                            loading: false,
                            errorMessage: null,
                        },4000);
                        props.setData({
                            clientId:"",
                            clientSecret:"",
                            appKey:"",
                            tenetId:"",
                            webOnOff:"OFF",
                            voiceOnOff:"OFF",
                            createUserOnOff:"OFF",
                            turboON:"OFF",
                            id:0  
                        })
                    }, 4000);
                }     
            })
        }
      };

      const updateStStatus = (value) => {
        setLoading(true)
        let formData = {
            user_chat_on:value,
            user_engage_on:props.engageOnValue,
            type:"chat",
        }
        if(props.data.id==undefined){
            formData["id"]=props.dataEngage.id;
        }else{
            formData["id"]=props.data.id;
        }
        api.servicetitan.updateStStatus({data:formData}).then(resp=>{
            if(resp.status == true){
                setLoading(false)
                if(resp.type == "chat"){
                    if(resp.action == "0"){
                        setIsEnabled(false)
                    }else{
                        setIsEnabled(true)
                        getListData();
                    }
                }
                setdefaultState({})
                }     
            })
        }

      const stAddedBranchList = (workspace_id) => {
        setWorkSpaceId(workspace_id)
          const data={
              user_id:props.user.user_id,
              workspace_id:turboBranchNameList.workspaceId,
            }
            api.botbuilder.serviceTitanAddedBranchList(data)
            .then((resp)=>{
                if(resp.status){
                    setAddedChatBranchName(resp.data)
                }
            })
            .catch((err) => {
                console.log(err)
            })
        }

        const stAddedAiBranchList = (workspace_id) => {
            setAiWorkSpaceId(workspace_id)
              const data={
                  user_id:props.user.user_id,
                  workspace_id:workspace_id.workspace.auto_id,
                }
                api.botbuilder.serviceTitanAddedBranchList(data)
                .then((resp)=>{
                    if(resp.status){
                        setAddedAiChatBranchName(resp.data)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
            }


    const getChatBranchName = () => {
        const data={
             user_id:props.user.user_id,
             bot_type:"web"
         }
         api.botbuilder.servieTitanchatBranchList(data)
             .then((resp)=>{
                 if(resp.status){
                    //getChatServiceProviderName()
                     setChatBranchNameList(resp.data)
                     stAddedBranchList(resp.data)
                 }
             })
             .catch((err) => {
                 console.log(err)
            })
     }

     const getChatServiceProviderName = () => {
         api.servicetitan.servieTitanProviderList()
             .then((resp)=>{
                     setChatServiceProviderList(resp)
                     setCallServiceProviderList(resp)
                     setTurboServiceProviderList(resp)
             })
             .catch((err) => {
                 console.log(err)
            })
     }
     const getAiCallBranchName = () => {
        const data={
             user_id:props.user.user_id,
             bot_type:"voice"
         }
         api.botbuilder.servieTitanchatBranchList(data)
             .then((resp)=>{
                 if(resp.status){
                     console.log(resp.data)
                     //getChatServiceProviderName()
                     setAiCallBranchNameList(resp.data)
                     stAddedAiBranchList(resp.data)
                 }
             })
             .catch((err) => {
                 console.log(err)
            })
     }

     //servieTitanchatTurboBranchList

     const servieTitanchatTurboBranchList = () => {
        const data={
            user_id:props.user.user_id,
            bot_type:"turbo"
        }
        api.botbuilder.servieTitanchatBranchList(data).then((res) => {
            if (res.status) {
                console.log(res.data.data,'turbob');
                //getChatServiceProviderName()
                setTurboBranchNameList(res.data);
                stAddedTurboBranchList(res.data);
            }
          }).catch((err) => {
            console.log(err);
          })
     }
     const getTurboData=()=>{

     }

     const stAddedTurboBranchList = (workspace_id) => {
        setAiWorkSpaceId(workspace_id)
          const data={
              user_id:props.user.user_id,
              workspace_id:workspace_id.workspace.auto_id,
            }
            api.botbuilder.serviceTitanAddedBranchList(data)
            .then((resp)=>{
                if(resp.status){
                    setAddedTurboBranchName(resp.data)
                }
            })
            .catch((err) => {
                console.log(err)
            })
        }

    const ChatStToggle=(id)=>{

    }

    
    const addBranchName = (e) => {
        console.log(selectChatProviderName)
        if((selectChatBranchName !== null && selectChatBranchName !== 'null-branch' && selectChatProviderValue !== null && selectChatProviderValue !== 'null-service-provider') || chatBranchNameList.branches>0){
            setLoading(true)
            setDisabledWebAddBtn(true)
            const data={
            user_id:props.user.user_id,
            workspace_id:chatBranchNameList.workspace.auto_id,
            branch:selectChatBranchName,
            tag_id:selectChatProviderValue,
            tag_name:selectChatProviderName
        }
        api.botbuilder.saveStBranchName(data)
            .then((resp)=>{
                if(resp.status){
                    setLoading(false)
                    setDisabledWebAddBtn(false)
                    setAddedChatBranchName(resp.data)
                    getChatBranchName();
                    setSelectBranchName(null)
                    setSelectChatProviderValue(null)
                    setSelectChatProviderName(null)
                    //getChatServiceProviderName()
                   // setChatServiceProviderList(null)

                }
            })
            .catch((err) => {
                setLoading(false)
                console.log(err)
            })
        }else{
            setNullWebBranch(true)
            setTimeout(()=>{
                setNullWebBranch(false)
            },5000)
        }    
    }

    const addAiBranchName = (e) => {
        console.log(selectChatCallProviderValue)
        if((selectAiChatBranchName !== null && selectAiChatBranchName !== 'null-branch' && selectChatCallProviderValue !== null &&  selectChatCallProviderValue !== 'null-service-provider') || aiCallBranchNameList.branches>0){
            setLoading(true)
            setDisabledWebAiAddBtn(true)
            const data={
            user_id:props.user.user_id,
            workspace_id:aiCallBranchNameList.workspace.auto_id,
            branch:selectAiChatBranchName,
            tag_id:selectChatCallProviderValue,
            tag_name:selectChatCallProviderName
        }
        api.botbuilder.saveStBranchName(data)
            .then((resp)=>{
                if(resp.status){
                    setLoading(false)
                    setDisabledWebAiAddBtn(false)
                    setAddedAiChatBranchName(resp.data)
                    getAiCallBranchName()
                    setSelectAiBranchName(null)
                    setSelectChatCallProviderValue(null)
                   // setCallServiceProviderList(null)
                }
            })
            .catch((err) => {
                setLoading(false)
                console.log(err)
            })
        }else{
            setNullAiWebBranch(true)
            setTimeout(()=>{
                setNullAiWebBranch(false)
            },5000)
        }
    }

    const addTurboBranchName = (e) => {
        console.log(selectTurboProviderValue)
        if((selectTurboBranchName !== null && selectTurboBranchName !== 'null-branch' && selectTurboProviderValue !== null &&  selectTurboProviderValue !== 'null-service-provider') || turboBranchNameList.branches>0){
            setLoading(true);
            setDisabledTurboAddBtn(true)
            const data={
            user_id:props.user.user_id,
            workspace_id:turboBranchNameList.workspace.auto_id,
            branch:selectTurboBranchName,
            tag_id:selectTurboProviderValue,
            tag_name:selectTurboProviderName,
            type:'turbo'

        }
        api.botbuilder.saveStBranchName(data)
            .then((resp)=>{
                if(resp.status){
                    setLoading(false)
                    setDisabledTurboAddBtn(false);
                    setAddedTurboBranchName(resp.data);
                    servieTitanchatTurboBranchList();
                    setSelectTurboBranchName(null);
                    setSelectTurboProviderValue(null);
                    //setTurboServiceProviderList(null);
                }
            })
            .catch((err) => {
                setLoading(false)
                console.log(err)
            })
        }else{
            setNullTurboBranch(true)
            setTimeout(()=>{
                setNullTurboBranch(false);
            },5000)
        }
    }

    const deleteBranchNamePopup = (branch_id,type) => {
        setDeleteBranchAlert(true)
        setDeleteBranchId(branch_id)
        setDeleteBranchType(type)
    }

    const deleteBranchName = () => {
        setDeleteBranchAlert(false)
        setLoading(true)
        const data={
            branch_id:deleteBranchId

        }
        api.botbuilder.serviceTitanWebDeleteBranch(data)
            .then((resp)=>{
                if(resp.status){
                    if(deleteBranchType=='web'){
                        getChatBranchName();
                        setTimeout(()=>{
                            setLoading(false)
                        },1500)
                        console.log(resp)
                    }
                    if(deleteBranchType=='ai'){
                        getAiCallBranchName();
                        setTimeout(()=>{
                            setLoading(false)
                        },1500)
                    }
                    if(deleteBranchType=='turbo'){
                        servieTitanchatTurboBranchList();
                        setTimeout(()=>{
                            setLoading(false)
                        },1500)
                    }
                }
            })
            .catch((err) => {
                setLoading(false)
                console.log(err)
                })
    }
    const handleBranchName = (e, type) => {
        if(type=='1'){
            setNullWebBranch(false)
            setSelectBranchName(e.target.value)
        }
        if(type=='webServiceProvider'){
            let index = e.target.selectedIndex;
            let optionElement = e.target.childNodes[index]
            setNullWebBranch(false)
            setSelectChatProviderValue(e.target.value)
            setSelectChatProviderName(getTagName(optionElement.innerHTML))
        }
        if(type=='2'){
            setNullAiWebBranch(false)
            setSelectAiBranchName(e.target.value)
        }
        if(type=='4'){
            console.log(e.target.value);
            setNullTurboBranch(false)
            setSelectTurboBranchName(e.target.value);
        }
        if(type=='callServiceProvider'){
            let index = e.target.selectedIndex;
            let optionElement = e.target.childNodes[index]
            setNullAiWebBranch(false)
            setSelectChatCallProviderValue(e.target.value)
            setSelectChatCallProviderName(getTagName(optionElement.innerHTML))
        }
        if(type=='TurboServiceProvider'){
            console.log(e.target.value,'turbosel');
            let index = e.target.selectedIndex;
            let optionElement = e.target.childNodes[index]
            setNullTurboBranch(false);
            setSelectTurboProviderValue(e.target.value)
            setSelectTurboProviderName(getTagName(optionElement.innerHTML))
        }
    }
    const getTagName=(tag)=>{
         let tags=tag.split("(");
         return tags[0].trim();
    }

    const closeInstructionModal = () => {
        setModel2(false);
        setVideoURL("");
    }

    const openInstructionModal = ()=>{
        setModel2(true);
        setVideoURL('https://www.youtube.com/embed/1BGQmwmKzdU');
    }

    const editCredentials=()=>{
        setClientIdTitan("")
        setClientSecretTitan("")
        setAppKeyTitan("")
        setTenantIdTitan("")
        setContinueBtn(true)
        setDiscardBtn(true)
    }

    const branchOptionList = () => {
        const firstIndex = {
          key: 'anybranch',
          text: 'Any branch',
          value: 'anybranch'
        };
        console.log()
        
        let list =turboBranchNameList?.data?.map((key, index) => {
          return {
            key: key.branch_id,
            text: key.branch_name,
            value: key.branch_id
          }
        });
        
        list.splice(0, 0, firstIndex);
        console.log(list,"list..")
        return list;
      }
//    const branchOptionListval=(item)=>{
//         console.log(item,'item')
//          let key=item[0]?.key;
//          let vals=item[0]?.val;
//          console.log(item,key,vals);
//          if(vals!=''){
//           vals=vals.map((v)=>{
//              return {key:v,text:v,value:v}
//            });
//            console.log(vals,"vals ..");
//            return vals;
//          }
//          return [];
//       }


    return (
        <>
        <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="kt-portlet__body kt-portlet__body--fit">
                    {defaultState.successMessage && (
                        <Message color={"green"}>{defaultState.successMessage}</Message>
                    )}
                    {defaultState.errorMessage && (
                        <Message color={"red"}>{defaultState.errorMessage}</Message>
                    )}
                    {defaultState.loading && <div className="ui loading form"></div>}
                    {loading && <div className='ui loading form'></div>}

                    {disableModalIsOpen && isEnabled && <ConfirmDialog
                            show={disableModalIsOpen}
                            toggleModal={disableServiceTitan}
                            closeAction={disableModalClose}
                            title="Confirm your action"
                            message={"Do you really want to disable Service Titan?"}
                        />
                    }
                    {enableModalIsOpen && <ConfirmDialog
                            show={enableModalIsOpen}
                            toggleModal={()=>enableServiceTitan("1")}
                            closeAction={enableModalClose}
                            title="Confirm your action"
                            message={"Do you want to enable Service Titan?"}
                        />}

                    {deleteBranchAlert && <ConfirmDialog
                            show={deleteBranchAlert}
                            toggleModal={deleteBranchName}
                            closeAction={deleteModalClose}
                            title="Confirm your action"
                            message={"Do you want to delete this branch?"}
                        />}

                    <div class="form-group row">
                        <div class="col-lg-12 col-md-12 col-sm-12 form-group-sub d-flex flex-row ">
                        <label class="form-control-label">Enable chat transcript to feed into ServiceTitan</label>
                        <div style={{ margin: "3px 175px 0 10px" }}>
                            <Switch
                            onChange={handleSwitch}
                            checked={isEnabled}
                            onColor="#86d3ff"
                            onHandleColor="#2693e6"
                            handleDiameter={30}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={20}
                            width={48}
                            className="react-switch"
                            id="material-switch"
                            />
                        </div>
                        <button
                            style={{ margin: "0 10px 0px 80px", float: "right", height:"auto", width:"165px" }}
                            type="button"
                            className="btn linear-gradient yr-submitbtn"
                            onClick={openInstructionModal}
                        >
                            Watch Tutorial
                        </button>
                        {updateCredBtn && <button
                            style={{ margin: "0 10px 0px 10px", float: "right", height:"auto", width:"200px" }}
                            type="button"
                            className="btn linear-gradient yr-submitbtn"
                            onClick={editCredentials}
                        >
                            Update Credentials
                        </button>}
                        </div>
                    </div>
 
                    <Form autoComplete="off" id="formChat" >
                        {isEnabled && <div class="form-group row">
                            <div class="col-lg-12 col-md-12 col-sm-12 form-group-sub mt-2">
                            <div class="form-group-sub">
                                <label class="form-control-label">
                                Client Id
                                </label>
                                <input onChange={handleChange} name="clientId" style={{height:"44px"}} value={clientIdTitan}>
                                
                                </input> 
                                {(errors.clientId && disableAlert) && (
                                <div className="red" pointing={"true"}>
                                {errors.clientId}
                                </div>
                                )}
                            </div>
                            <div class="form-group-sub">
                                <label class="form-control-label">
                                Client Secret
                                </label>
                                <input onChange={handleChange} name="clientSecret" style={{height:"44px"}} value={clientSecretTitan}>
                                
                                </input> 
                                {(errors.hasOwnProperty('clientSecret') && disableAlert) && (
                                <div className="red">
                                {errors.clientSecret}
                                </div>
                                )}
                            </div>
                            <div class="form-group-sub">
                                <label class="form-control-label">
                                App Key
                                </label>
                                <input onChange={handleChange} name="appKey" style={{height:"44px"}} value={appKeyTitan}>
                                
                                </input> 
                                {(errors.appKey && disableAlert) && (
                                <div className="red" pointing={"true"}>
                                {errors.appKey}
                                </div>
                                )}
                            </div>
                            <div class="form-group-sub">
                                <label class="form-control-label">
                                Tenant Id
                                </label>
                                <input onChange={handleChange} name="tenetId" style={{height:"44px"}} value={tenantIdTitan}></input> 
                                {(errors.hasOwnProperty('tenetId') && disableAlert) && (
                                <div style={{color:'red'}} pointing={"true"}> {errors.tenetId}</div>
                                )}
                            </div>
                                
                            <div class="col-lg-12 col-md-12 col-sm-12 form-group-sub mt-5">
                                {continueBtn && 
                                <button
                                type="submit"
                                        style={{ float: "right", marginLeft:"20px" }}
                                className="btn linear-gradient yr-submitbtn btn-sm"
                                onClick={modalBeforeEnable}
                                >
                                Continue
                                    </button>}
                                    {discardBtn &&
                                    <button
                                        style={{ float: "right"}}
                                        className="btn linear-gradient yr-submitbtn btn-sm"
                                        onClick={()=>getListData()}
                                        >
                                        Discard Changes
                                </button>
                                    }
                            </div>
                            </div>
                            {showStToggles && <div style={{marginTop:"20px"}}>
                                <div style={{display:"flex", justifyContent:"space-between"}} class="col-lg-16 col-md-12 col-sm-12 form-group-sub d-flex flex-row ">
                                    <label style={{maxWidth:"400px"}} class="form-control-label">Enable bookings into serviceTitan via chat</label>
                                    <div style={{ margin: "3px 175px 0 10px" }}>
                                        <Switch
                                        onChange={(e)=>handleToggles('1')}
                                        checked={webChecked=='OFF' ? false : true} 
                                        onColor="#86d3ff"
                                        onHandleColor="#2693e6"
                                        handleDiameter={30}
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                        height={20}
                                        width={48}
                                        className="react-switch"
                                        id="material-switch"
                                        />
                                    </div>
                                </div>
                                {stChatEnabled=='ON' && <div style={{width:"200px", marginLeft:"10px"}}>
                                    <div style={{display:"flex", alignItems:"center", width:"520px"}}>
                                        <select onChange={(e)=>handleBranchName(e,'1')} style={{color: "#848588", width:"100%", height:"44px"}} class="form-control">
                                        <option style={{fontWeight:"bolder"}} value={"null-branch"}>Select branch name</option>
                                            {chatBranchNameList?.branches?.map((data)=>(
                                                <option class="form-control-label"  name={data.branch_name} key={data.branch_id} value={data.branch_id}>{data.branch_name}</option>
                                            ))}
                                        </select>
                                        <select onChange={(e)=>handleBranchName(e,'webServiceProvider')} style={{color: "#848588", width:"100%", height:"44px", marginLeft:"10px"}} class="form-control">
                                        <option style={{fontWeight:"bolder"}} data-name="null-name" value={"null-service-provider"}>Select booking provider</option>
                                            {chatServiceProviderList?.servicetitaninfo?.map((data)=>(
                                                <option class="form-control-label"  name={data.tagName} key={data.id} value={data.id}>{data.tagName} ({data.id})</option>
                                            ))}
                                        </select>
                                        <div style={{display:"flex", alignItems:"center"}}>
                                            <button
                                            onClick={(e)=>addBranchName(e)}
                                            style={{marginLeft:"10px"}}
                                            className="btn linear-gradient yr-submitbtn btn-sm"
                                            disabled={disabledWebAddBtn}
                                            >
                                            Add
                                            </button>
                                        </div>
                                    </div>
                                    <div>
                                    {nullWebBranch && <div style={{marginTop:"10px", color:"red", width:"500px"}}>No branch found or select service provider</div>}
                                        <ol>
                                            {addedChatBranchName?.map((branchNames)=>{
                                                let serviceProviderName=JSON.parse(branchNames.booking_tag)
                                                return(
                                                <div style={{display:"flex", width:"490px", justifyContent:"space-between", alignItems:"center"}}><li style={{ marginLeft:"-25px"}}><label style={{width:"225px"}} class="form-control-label">{branchNames.branch_name}</label><label style={{width:"215px", marginLeft:"20px"}}>{serviceProviderName.tagname} ({serviceProviderName.tagid})</label></li><button onClick={()=>deleteBranchNamePopup(branchNames.branch_id, 'web')} style={{width: "30px",height: "30px",borderRadius: "5px",border: "none", background:"transparent"}}><i class="la la-trash" aria-hidden="true"></i>
                                                </button></div>
                                            )})}
                                        </ol>
                                    </div>
                                </div>}
                                <div style={{display:"flex", justifyContent:"space-between"}} class="col-lg-16 col-md-12 col-sm-12 form-group-sub d-flex flex-row " >
                                    <label class="form-control-label">Enable bookings into serviceTitan via Ai calls</label>
                                    <div style={{ margin: "3px 175px 0 10px" }}>
                                        <Switch
                                        onChange={(e)=>handleToggles('2')}
                                        checked={aiChecked=='OFF' ? false : true}
                                        onColor="#86d3ff"
                                        onHandleColor="#2693e6"
                                        handleDiameter={30}
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                        height={20}
                                        width={48}
                                        className="react-switch"
                                        id="material-switch"
                                        />
                                    </div>
                                </div>
                                {stAiChatEnabled=='ON'  && <div style={{width:"200px", marginLeft:"10px"}}>
                                    <div style={{display:"flex", alignItems:"center", width:"520px"}}>
                                        <select onChange={(e)=>handleBranchName(e,'2')} style={{color: "#848588", width:"100%", height:"44px"}} class="form-control">
                                        <option style={{fontWeight:"bolder"}} value={"null-branch"}>Select branch name</option>
                                            {aiCallBranchNameList?.branches?.map((data)=>(
                                                <option class="form-control-label"  name={data.branch_name} key={data.branch_id} value={data.branch_id}>{data.branch_name}</option>
                                            ))}
                                        </select>
                                        <select onChange={(e)=>handleBranchName(e,'callServiceProvider')} style={{color: "#848588", width:"100%", height:"44px", marginLeft:"10px"}} class="form-control">
                                        <option style={{fontWeight:"bolder"}} value={"null-service-provider"}>Select service provider</option>
                                            {callServiceProviderList?.servicetitaninfo?.map((data)=>(
                                                <option class="form-control-label"  name={data.tagName} key={data.id} value={data.id}>{data.tagName} ({data.id})</option>
                                            ))}
                                        </select>
                                        <div style={{display:"flex", alignItems:"center"}}>
                                            <button
                                            onClick={(e)=>addAiBranchName(e)}
                                            style={{marginLeft:"10px"}}
                                            className="btn linear-gradient yr-submitbtn btn-sm"
                                            disabled={disabledWebAiAddBtn}
                                            >
                                            Add
                                            </button>
                                        </div>
                                    </div>
                                    <div>
                                        {nullAiWebBranch && <div style={{marginTop:"10px", color:"red", width:"500px"}}>No branch found or select service provider</div>}
                                        <ol>
                                            {addedAiChatBranchName?.map((branchNames)=>{
                                                let serviceProviderName=JSON.parse(branchNames.booking_tag)
                                                return (
                                                <div style={{display:"flex", width:"490px", justifyContent:"space-between", alignItems:"center"}}><li style={{ marginLeft:"-25px"}}><label style={{width:"225px"}} class="form-control-label">{branchNames.branch_name}</label><label style={{width:"215px", marginLeft:"20px"}}>{serviceProviderName.tagname} ({serviceProviderName.tagid})</label></li><button onClick={()=>deleteBranchNamePopup(branchNames.branch_id, 'ai')} style={{width: "30px",height: "30px",borderRadius: "5px",border: "none", background:"transparent"}}><i class="la la-trash" aria-hidden="true"></i>
                                                </button></div>
                                            )})}
                                        </ol>
                                    </div>
                                </div>}

                                {/* for turbo */}
                                <div style={{display:"flex", justifyContent:"space-between"}} class="col-lg-16 col-md-12 col-sm-12 form-group-sub d-flex flex-row " >
                                    <label class="form-control-label">Enable bookings into serviceTitan via Ai calls(Turbo)</label>
                                    <div style={{ margin: "3px 175px 0 10px" }}>
                                        <Switch
                                        onChange={(e)=>handleToggles('4')}
                                        checked={turboChecked=='OFF' ? false : true}
                                        onColor="#86d3ff"
                                        onHandleColor="#2693e6"
                                        handleDiameter={30}
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                        height={20}
                                        width={48}
                                        className="react-switch"
                                        id="material-switch"
                                        />
                                    </div>
                                </div>
                                {stAiTurboEnabled=='ON'  && <div style={{width:"200px", marginLeft:"10px"}}>
                                    <div style={{display:"flex", alignItems:"center", width:"520px"}}>
                                        <select onChange={(e)=>handleBranchName(e,'4')} style={{color: "#848588", width:"100%", height:"44px"}} class="form-control">
                                        <option style={{fontWeight:"bolder"}} value={"null-branch"}>Select branch name</option>
                                            {turboBranchNameList?.branches?.map((data)=>(
                                                <option class="form-control-label"  name={data.branch_name} key={data.branch_id} value={data.branch_id}>{data.branch_name}</option>
                                            ))}
                                        </select>
                                        {/* <Dropdown
                                                key="turbo"
                                                name="turbo_branches"
												placeholder=''
												fluid
												multiple
												search
												selection
												options={branchOptionList()}
												defaultValue={this.state.selectedBranch}
												onChange={this.handleBranchSelection}
												// value={this.state.selectedBranch}
											/> */}
                                        <select onChange={(e)=>handleBranchName(e,'TurboServiceProvider')} style={{color: "#848588", width:"100%", height:"44px", marginLeft:"10px"}} class="form-control">
                                        <option style={{fontWeight:"bolder"}} value={"null-service-provider"}>Select service provider</option>
                                            {turboServiceProviderList?.servicetitaninfo?.map((data)=>(
                                                <option class="form-control-label"  name={data.tagName} key={data.id} value={data.id}>{data.tagName} ({data.id})</option>
                                            ))}
                                        </select>
                                        <div style={{display:"flex", alignItems:"center"}}>
                                            <button
                                            onClick={(e)=>addTurboBranchName(e)}
                                            style={{marginLeft:"10px"}}
                                            className="btn linear-gradient yr-submitbtn btn-sm"
                                            disabled={disabledWebAiAddBtn}
                                            >
                                            Add
                                            </button>
                                        </div>
                                    </div>
                                    <div>
                                        {nullTurboBranch && <div style={{marginTop:"10px", color:"red", width:"500px"}}>No branch found or select service provider</div>}
                                        <ol>
                                            {addedTurboBranchName?.map((branchNames)=>{
                                                let serviceProviderName=JSON.parse(branchNames.booking_tag)
                                                return (
                                                <div style={{display:"flex", width:"490px", justifyContent:"space-between", alignItems:"center"}}><li style={{ marginLeft:"-25px"}}><label style={{width:"225px"}} class="form-control-label">{branchNames.branch_name}</label><label style={{width:"215px", marginLeft:"20px"}}>{serviceProviderName.tagname} ({serviceProviderName.tagid})</label></li><button onClick={()=>deleteBranchNamePopup(branchNames.branch_id, 'turbo')} style={{width: "30px",height: "30px",borderRadius: "5px",border: "none", background:"transparent"}}><i class="la la-trash" aria-hidden="true"></i>
                                                </button></div>
                                            )})}
                                        </ol>
                                    </div>
                                </div>}
                                <div style={{display:"flex", justifyContent:"space-between"}} class="col-lg-16 col-md-12 col-sm-12 form-group-sub d-flex flex-row ">
                                    <label class="form-control-label">Create a new customer in Service Titan (This will not update exisiting customer records)</label>
                                    <div style={{ margin: "3px 175px 0 10px" }}>
                                        <Switch
                                        onChange={(e)=>handleToggles('3')}
                                        checked={createUserChecked=='OFF' ? false : true}
                                        onColor="#86d3ff"
                                        onHandleColor="#2693e6"
                                        handleDiameter={30}
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                        height={20}
                                        width={48}
                                        className="react-switch"
                                        id="material-switch"
                                        />
                                    </div>
                                </div>
                            </div>}

                          
                            {showStToggles && <div class="col-lg-12 col-md-12 col-sm-12 form-group-sub mt-5">
                                <button
                                type="submit"
                                style={{ float: "right" }}
                                className="btn linear-gradient yr-submitbtn btn-sm"
                                onClick={finalServiceTitanSave}
                                >
                                Save
                                </button>
                            </div>}
                        </div>}
                    </Form>
                </div>
            </div>
        </div>
        <div
            className={model2 ? "modal fade show " : "modal fade"}
            data-backdrop="static"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="staticBackdrop"
            aria-hidden="true"
            style={{ display: model2 ? "block" : "none" }}
        >
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <button
                        type="button"
                        className="close linear-gradient"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={closeInstructionModal}
                    >
                        X
                    </button>
                    <div className="modal-body">
                    <iframe width="100%" height="480" style={{aspectRatio: '16/9'}} src={videoURL} title="Service Titan Integration" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </div>
            </div>

        </div>
        </>
    )
}

export default ServiceTitan;