import axios from "../../config/axios";

const webhook = {
  loadWebhook: (params) =>
    axios
      .post("/integration/webhook/", params)
      .then((resp) => (resp ? resp.data : null)),

  createWebhook: (params) =>
    axios
      .post("/integration/webhook/create", params)
      .then((resp) => (resp ? resp.data : null)),

  deleteWebhook: (params) =>
    axios
      .post("/integration/webhook/delete", params)
      .then((resp) => (resp ? resp.data : null)),
  getWebhookInfo: (params) =>
        axios
            .post("/integration/webhook/getWebhookInfo", params)
            .then((resp) => (resp ? resp.data : null)),
};

export default webhook;
