import axios from "../config/axios";
import config from "../config/index";
const transcriptchatlist = {
  
  getLists: (params) =>
  axios
      .get(`/chatlist/TranscriptChatlist/getchatlist?` + params, {})
      .then((resp) => resp.data)
      .catch((err) => console.log(err)),

    getVoiceChatData: (params) =>
        axios
            .get(`/chatlist/TranscriptChatlist/getVoiceChatData?` + params, {})
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),
    viewVoiceChatDetails: (params) =>
        axios
            .post('/chatlist/TranscriptChatlist/viewVoiceChatDetails', params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),       
  
    getVoiceTeamNotes: (params) =>
        axios
            .post('/chatlist/TranscriptChatlist/getVoiceTeamNotes', params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),           
    getTeamNotes: (params) =>
      axios
          .post('/chatlist/TranscriptChatlist/getTeamNotes', params)
          .then((resp) => resp.data)
          .catch((err) => console.log(err)),   
          
     viewDetails: (params) =>
          axios
              .post('/chatlist/TranscriptChatlist/viewDetails', params)
              .then((resp) => resp.data)
              .catch((err) => console.log(err)),    
    saveVoiceTeamNotes: (params) =>
        axios
            .post('/chatlist/TranscriptChatlist/saveVoiceTeamNotes', params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),
     saveTeamNotes: (params) =>
              axios
                  .post('/chatlist/TranscriptChatlist/saveTeamNotes', params)
                  .then((resp) => resp.data)
                  .catch((err) => console.log(err)),   
    deleteVoiceNote: (params) =>
        axios
            .post('/chatlist/TranscriptChatlist/deleteVoiceNote', params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)), 
     deleteTeamNotes: (params) =>
                  axios
                      .post('/chatlist/TranscriptChatlist/deleteNote', params)
                      .then((resp) => resp.data)
                      .catch((err) => console.log(err)),  
    
     changeNotesStatus: (params) =>
                      axios
                          .post('/chatlist/TranscriptChatlist/changestatus', params)
                          .then((resp) => resp.data)
                          .catch((err) => console.log(err)),  
        
    getCSVData: (params) =>
                          axios
                              .post('/chatlist/TranscriptChatlist/exportChats', params)
                              .then((resp) => resp.data)
                              .catch((err) => console.log(err)),    

    getVoiceCSVData: (params) =>
        axios
            .post('/chatlist/TranscriptChatlist/exportVoiceChats', params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),   

    getvoiceChatExport: (params) =>
        axios
            .post(`${config.apiBaseURL2}getvoiceChatExport`, params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),        

    deleteNotes: (params) =>
        axios
            .post('/chatlist/TranscriptChatlist/deleteNotes', params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),                 
    fetchparticularNotes: (params) =>
        axios
            .post('/chatlist/TranscriptChatlist/fetchparticularNotes', params)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)), 

    reGenerateParseData: (params) =>
        axios
    .post(`${config.apiBaseURL2}re-generate-parsedata`, params)
    .then((resp) => resp.data)
    .catch((err) => console.log(err)),                        

    viewVoiceLog: (params) =>
        axios
    .post(`${config.apiBaseURL2}viewVoiceNotificationLog`, params)
    .then((resp) => resp.data)
    .catch((err) => console.log(err)),

                          
           

  
};

export default transcriptchatlist;
