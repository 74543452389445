import React,{ useState,useEffect } from 'react'
import api from "../../../api";
const ButtonClick = (props) => {
    const [buttonClick, setButtonClick] = useState({});
    const [button_ids, setButton_ids] = useState([]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
              getBtnData(props);
    }, [props.chatfrequency, props.startdaterange, props.enddaterange]);
      
          
    const getBtnData = (props) => {
        setLoading(true);
        const params = {
        frequency: props.chatfrequency,
        filterstartdate: props.startdaterange,
        filterenddate: props.enddaterange
        };
          const esc = encodeURIComponent;
          const query = Object.keys(params)
            .map((k) => esc(k) + "=" + esc(params[k]))
            .join("&");
             api.chatsetup.getBtnData(query).then((res) => {
              try {
                  if (res.status) {
                      setLoading(false);
                      setButtonClick(res.buttonClick);
                      setButton_ids(res.buttonClickKeyIds);
                  }
              } catch (error) {
                  console.log(error);
                  setLoading(false);
                  
              }
                 
              });
          }
    const buttonClickHtml=()=>{
        const dataCount = buttonClick;
        if (Object.keys(dataCount).length === 0) {
            return (
                <ul class="popularUrlList">
                    <li>
                        <span>Records not found.</span>
                    </li>
                </ul>
                
            )
        } else {
            let sortByTopClick = null;
            if (Object.keys(dataCount).length > 1) {
                sortByTopClick = Object.fromEntries(
                    Object.entries(dataCount).sort(([, a], [, b]) => b - a)
                );
            } else {
                sortByTopClick = dataCount;
            }
        
            return(<>
                {Object.entries(sortByTopClick).map((btn,i)=>{
                    return(<li style={{cursor:'pointer'}} key={i} onClick={(e) => handleClick(e,button_ids[btn[0]],btn[0])}><p>{btn[0]}</p><div><b>{sortByTopClick[btn[0]]}</b></div></li>);
                })}
                </>);
            
        }
    }
    const handleClick=(e,id,name)=>{
        if(id && typeof id !=="undefined" && id!=''){
            props.showModal(id,name);
        }
      
    }
    
    return (
        <ul className="zy-clickCounts">
            {/* <div dangerouslySetInnerHTML={{__html: buttonClickHtml }} /> */}
            {loading ? 
      <div style={{ width: '100%', height: 200 }}>
					<div class="ui active centered inline loader"></div>
				</div>
       :
        <div>{buttonClickHtml()}</div>}
        </ul>
    )
}

export default ButtonClick