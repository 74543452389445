import React,{ useState,useEffect } from 'react'
import { Link } from "react-router-dom";
import api from "../../../api";

const Top10Url = (props) => {
        const [top10url, settop10url] = useState([]);
        const [loading, setLoading] = useState(false);
        
        useEffect(() => {
             getBtnData(props);
        }, [props.chatfrequency, props.startdaterange, props.enddaterange]);
          
              
        const getBtnData = (props) => {
            setLoading(true);
            const params = {
            frequency: props.chatfrequency,
            filterstartdate: props.startdaterange,
            filterenddate: props.enddaterange
            };
              const esc = encodeURIComponent;
              const query = Object.keys(params)
                .map((k) => esc(k) + "=" + esc(params[k]))
                .join("&");
                 api.chatsetup.getchatUrls(query).then((res) => {
                  try {
                      if (res.status) {
                          setLoading(false);
                         settop10url(res.top10url);
                      }
                  } catch (error) {
                      console.log(error);
                      setLoading(false);
                      
                  }
                     
                  });
    }
   
   if(loading){
        return(<div style={{ width: '100%', height: 200 }}>
            <div class="ui active centered inline loader"></div>
        </div>);
   }else{
    if (top10url.length === 0) {
        return (
            <li>
                <span>Records not found.</span>
            </li>
        )
    } else {
        const listItems = top10url.map((url, i) =>

            <li key={i}>
                <p style={{lineBreak : 'anywhere', paddingRight: 10}}>{url.domainName}</p>
                <strong>{url.total}</strong>
            </li>

        );
        return ( <ul>{listItems}</ul> );
    }
   }
    

}

export default Top10Url