import React,{ useState,useEffect } from 'react'
import { ResponsiveContainer, LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import api from "../../../api";

const ChatGraph = (props) => {
  const [graphdata, setGraphdata] = useState([]);
      const [loading, setLoading] = useState(false);
      useEffect(() => {
          getGraph(props);
      }, [props.chatfrequency, props.startdaterange, props.enddaterange]);
  
      
      const getGraph = (props) => {
          setLoading(true);
          const params = {
        frequency: props.chatfrequency,
        filterstartdate: props.startdaterange,
        filterenddate: props.enddaterange
      };
      const esc = encodeURIComponent;
      const query = Object.keys(params)
        .map((k) => esc(k) + "=" + esc(params[k]))
        .join("&");
        let chartData = null;
        let newchrt = [];
         api.chatsetup.getchatgraph(query).then((res) => {
          try {
              if (res.status) {
               
                if (res.chatchartyaxis?.dataquantity?.seriespoint && Object.keys(res.chatchartyaxis?.dataquantity?.seriespoint).length === 0) {
                  chartData = null;
                } else {
                  chartData = [...Object.values(res.chatchartyaxis?.dataquantity?.seriespoint)];
                }
                if (chartData) {
                  newchrt = chartData.map((item) => (
                    {
                      'name': item.year,
                      'Started': item.started,
                      'Completed': item.completed,
                      'Live Chat': item.livechat
                    }
                  ));
                }
                  setLoading(false);
                  setGraphdata(newchrt);
              }
          } catch (error) {
              console.log(error);
              setLoading(false);
              
          }
             
          });
      }
  
  return(
    <>
      {loading ? 
      <div style={{ width: '100%', height: 200 }}>
					<div class="ui active centered inline loader"></div>
				</div>
       :<ResponsiveContainer width="100%" height="100%">
      <LineChart data={graphdata}
        margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
        <CartesianGrid strokeDasharray="5 5" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey="Started" stroke="#8884d8" activeDot={{ r: 5 }} />
        <Line type="monotone" dataKey="Completed" stroke="#82ca9d" activeDot={{ r: 5 }} />
        <Line type="monotone" dataKey="Live Chat" stroke="#ff03dd" activeDot={{ r: 5 }} />
        
      </LineChart>
    </ResponsiveContainer>}
    </>
   
    );
}

export default ChatGraph;

